import React from 'react';

import './styles.scss';

// 1 экран - видео и логотип
export const MainVideo: React.FC = () => (
    <main id="sec-main" className="section-main h-100vh">
        {/* <video src="main.mp4" loop autoPlay muted />
        <div className="d-flex align-items-center justify-content-center h-100vh">
            <img className="logo" src="/images/logo.svg" alt="ShareSoft" />
        </div> */}
    </main>
);
