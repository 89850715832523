import React from 'react';
import { Section } from '../section';
import { FeedbackForm } from './components/feedback-form';
import { useTranslation } from 'react-i18next';


// Экран "Запрос демонстрации"
export const Feedback: React.FC = () => {
    const {t} = useTranslation()

return(

    <Section
        id="sec-form"
        title={t('feedback.title')}
        color="color-2"
        wrapper
    >
        <FeedbackForm />
    </Section>
)};
