import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Home, Error,SharelockInfo } from './pages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';


import './i18n/config';


export const App: React.FC = () => (
    <ParallaxProvider>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/sharelock" component={SharelockInfo} />
                <Route path="*" component={Error} />
            </Switch>
        </BrowserRouter>
    </ParallaxProvider>
);
