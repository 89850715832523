import React from "react";
import { Section } from "../section";

import { useState } from "react";
import "./styles.scss";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

// Экран "Преимущества работы с нами"
export const Benefits: React.FC = () => {
  let [answerParagraphState1, setAnswerParagraphState1] = useState(false);
  let [answerParagraphState2, setAnswerParagraphState2] = useState(false);

  let [answerParagraphState3, setAnswerParagraphState3] = useState(false);

  let [answerParagraphState4, setAnswerParagraphState4] = useState(false);

  let [answerParagraphState5, setAnswerParagraphState5] = useState(false);

  let [answerParagraphState6, setAnswerParagraphState6] = useState(false);

  let [answerParagraphState7, setAnswerParagraphState7] = useState(false);

  let [answerParagraphState8, setAnswerParagraphState8] = useState(false);



  function answerClickHandler(id: number) {
    switch (id) {
      case 1:
        setAnswerParagraphState1(!answerParagraphState1);
        if(answerParagraphState1){
          document.getElementById("FAQContainer1")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer1")?.classList.add("FAQContainerBorder")

        }
        break;
      case 2:
        setAnswerParagraphState2(!answerParagraphState2);
        if(answerParagraphState2){
          document.getElementById("FAQContainer2")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer2")?.classList.add("FAQContainerBorder")

        }
        break;
      case 3:
        setAnswerParagraphState3(!answerParagraphState3);
        if(answerParagraphState3){
          document.getElementById("FAQContainer3")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer3")?.classList.add("FAQContainerBorder")

        }
        break;
      case 4:
        setAnswerParagraphState4(!answerParagraphState4);
        if(answerParagraphState4){
          document.getElementById("FAQContainer4")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer4")?.classList.add("FAQContainerBorder")

        }
        break;

      case 5:
        setAnswerParagraphState5(!answerParagraphState5);
        if(answerParagraphState5){
          document.getElementById("FAQContainer5")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer5")?.classList.add("FAQContainerBorder")

        }
        break;
      case 6:
        setAnswerParagraphState6(!answerParagraphState6);
        if(answerParagraphState6){
          document.getElementById("FAQContainer6")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer6")?.classList.add("FAQContainerBorder")

        }
        break;
      case 7:
        setAnswerParagraphState7(!answerParagraphState7);
        if(answerParagraphState7){
          document.getElementById("FAQContainer7")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer7")?.classList.add("FAQContainerBorder")

        }
        break;
      case 8:
        setAnswerParagraphState8(!answerParagraphState8);
        if(answerParagraphState8){
          document.getElementById("FAQContainer8")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer8")?.classList.add("FAQContainerBorder")

        }
        break;
    }
  }
  return (
    <Section
      id="sec-benefits"
      //   title={t("benefits.title")}
      color="#48979079"
      wrapper
    >
      <div>
        <h1 className="answersAndQuestionsH1">Вопросы и ответы</h1>
        <div id="FAQContainer1">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              Что если не все сотрудники знают какие документы являются
              конфиденциальными?
            </div>{" "}
            <div>
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(1)}
              >
                {answerParagraphState1 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState1 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
                Вам не надо обучать сотрудников различать какие документы надо
                защищать, а какие не надо – умный ШерЛок сам определяет
                конфиденциальность на основе предварительного машинного
                обучения.{" "}
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <hr />
        <div id="FAQContainer2">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              У нас более тысячи сотрудников, нам надо будет обучить всех
              сотрудников пользоваться новой системой?
            </div>{" "}
            <div>
              {" "}
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(2)}
              >
                {answerParagraphState2 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState2 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
                Нет. ШерЛок встраивается в офисный пакет и не требует
                дополнительного обучения. Достаточно следовать действиям,
                которые указываются в всплывающих окнах.{" "}
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer3">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              Что если конфиденциальные документы нам надо переслать по почте
              или передать внешним контрагентам не из нашей компании на период
              совместной работы?
            </div>{" "}
            <div>
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(3)}
              >
                {answerParagraphState3 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState3 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
                Вы можете настроить временный доступ к документу определенным
                лицам, через назначенный срок доступ закроется. При этом
                ограничить доступ только на чтение, без копии и печати
                документа.{" "}
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer4">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              Где хранить защищенные документы?
            </div>{" "}
            <div>
              {" "}
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(4)}
              >
                {answerParagraphState4 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState4 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
                Вам не надо думать, как и где его правильно хранить -
                посторонние не откроют документ, надежные криптоалгоритмы
                встраиваются в сам документ, независимо от места его
                расположения.{" "}
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer5">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              На каких офисных пакетах это работает?
            </div>{" "}
            <div>
              {" "}
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(5)}
              >
                {answerParagraphState5 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState5 ? (
            <div className="questionDiv">
              <br />
              <h3>Поддерживается MS Office, LibreOffice. </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer6">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              Как внешние пользователи откроют защищенный документ, если у них
              нет учетной записи нашей организации?
            </div>{" "}
            <div>
              {" "}
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(6)}
              >
                {answerParagraphState6 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState6 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
                Пройдя аутентификацию яндекс ID, госуслуги или другого
                провайдера, которому вы доверяете.{" "}
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer7">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              
Чем ваша система отличается от Microsoft Information Protection (MIP) <br /> или Right Management Services (AD RMS) ?


            </div>{" "}
            <div>
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(7)}
              >
                {answerParagraphState7 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState7 ? (
            <div className="questionDiv">
              <br />
              <h3>
                             
               ШерЛок более умный и функциональный, использует совместимые системы защиты и не зависит от работы Майкрософт в России.


              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <hr />

      
      </div>
    </Section>
  );
};
