import React, { useEffect } from "react";
import { Section } from "../section";
// import { FacebookPosts } from "./components/facebook-posts";
import { FaDownload} from "react-icons/fa";


import "./styles.scss";

// Экран "Последние новости"
export const News: React.FC = () => {
  // useEffect(()=>{
  //     var ftp = new FtpConnection("ftp://192.168.1.150/DATA/") ;
  // },[])
  return (
    <Section
      id="sec-news"
      title="Скачать примеры защищенных документов"
      color="color-1"
      wrapper
    >
      <div className="downloadFilesDiv">
 
              <a href={`${window.location.href}protected_document.docx`}>

          {" "}
          <div>
            <img
              id="filesIcon1"
              className="filesIcons"
              src="/images/word.png"
              alt="DOCX"
            />
                      <div> <span id="documentNameSpan">protected_document.docx</span>  <span><FaDownload id="downloadIcon"/></span></div>
          </div>
        </a>

      </div>
    </Section>
  );
};
