import React from 'react';

import './styles.scss';

export const Footer: React.FC = () => (
    <footer className="color-2">
        <div>2008 - {new Date().getFullYear()} Шаресофт</div>
        <div><a className='footerLinks' href='https://sharesoft.ru/'><img src="/images/world-globe-line-icon.svg" className="svgPhone svgCheckMark" alt="Sharesoft"></img>www.sharesoft.ru</a></div>
        <div><a className='footerLinks' href="tel:PHONE_NUM"><img src="/images/phone-line-icon.svg" className="svgPhone svgCheckMark" alt="Sharesoft"></img>  +7 (495) 690 91 79 </a></div>
        <div> <a className='footerLinks' href='mailto:info@sharesoft.ru'><img src="/images/envelope-line-icon.svg" className="svgPhone svgCheckMark" alt="Sharesoft"></img>info@sharesoft.ru</a></div>
        


        
    </footer>
);
