import React from 'react';

import './styles.scss';




// Навигация
export const Navbar = () => {

    return (
        <header className="section-header section-header--black">
            <div className="section-fixed">
                <div className="header-content d-flex align-items-center justify-content-between">
                    <div selector="#sec-main" className="logo-container">
                        <img className="logo-container__img logo-container__img--small" src="/images/logo_sharelock_svg.svg" alt="Sharesoft" />
                        <img id="logo_sharelock" className="logo-container__img logo-container__img--full" src="/images/logo_sharelock_svg.svg" alt="Sharesoft" />

                    </div>
                    {/* <div className="desc-container">
                    Описание версии Шерлок для реестра отечественного ПО
                    </div> */}
                    </div>

              

            </div>
        </header>
    );
};
