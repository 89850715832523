import React from 'react';
import {
    FuncInfoParagraphs
} from '../func-info-paragraphs';


// Экран "Разработка заказного программного обеспечения"
export const SharelockFuncDesc: React.FC = () => {


    return(    
    <div>
        <h1>
Функциональные возможности программного обеспечения «ШЕРЛОК» (ShareLock)
</h1>
<h2>Автор: ООО «Шаресофт-Л»</h2>
<FuncInfoParagraphs/>


    </div> ) };
