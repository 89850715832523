import React from "react";
import { Section } from "../section";
import { Projects } from "../../components";

import "./styles.scss";

// Экран "Аутстаффинг"
export const Outstaffing: React.FC = () => {

  return (
    <Section
      id="sec-main"
      title="ШерЛок - умная защита  документов"
      color="color-2"
      wrapper
    >
      <div className="videoDescDiv">
        <ul className="videoDescUl">
          <li className="videoDescList">
            <img
              className="svgCheckMark"
              src="/images/icon1.svg"
              alt="Sharesoft"
            />
            Распознает конфиденциальные документы <hr />
          </li>

          <li className="videoDescList">
            <img
              className="svgCheckMark"
              src="/images/icon2.svg"
              alt="Sharesoft"
            />
            Использует шифрование и уровни доступа к документу <hr />
          </li>

          <li className="videoDescList">
            <img
              className="svgCheckMark"
              src="/images/icon3.svg"
              alt="Sharesoft"
            />
            Документ защищен независимо от того, где он хранится{" "}
          </li><hr />
          <li className="videoDescList">
            <img
              className="svgCheckMark"
              src="/images/icon4.svg"
              alt="Sharesoft"
            />
            Заменяет импортные решения и совместим с Microsoft Right &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Management Service (AD RMS){" "}
          </li>
        </ul>
      </div>
      <div className="videoFrameDiv">
        {/* <iframe
      className="videoFrame"
      
        src="https://www.youtube.com/embed/UopuVbhPAP8"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="video"
      ></iframe> */}
        <Projects />
      </div>
    </Section>
  );
};
