import React from "react";
import { Carousel } from "react-bootstrap";
import { Section } from "../section";
import { ProjectCarouselItem } from "./components/projects-carousel-item";
import { ProjectsInfo } from "../../common";
import { useTranslation } from "react-i18next";

// Экран "Несколько наших последних проектов"
export const Projects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section id="sec-projects" color="color-1" wrapper>
      <div>
        {" "}
        <iframe
          className="videoFrame"
                  
          src={"https://www.youtube.com/embed/k0IlLI-Tdrc"}
          frameBorder="20"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        ></iframe>
        <h3> ШерЛок для Microsoft Office и LibreOffice</h3>
        <h4>
          Демо по распознаванию и защите конфиденциальных документов с
          использованием нейросети.
        </h4>
      </div>
      {/* <Carousel>
        {ProjectsInfo.map((item, key) => (
          <ProjectCarouselItem
            // prevPath = {item.prevPath}
            // nextPath ={item.nextPath}
            path={item.path}
            title={t(item.title)}
            description={t(item.description)}
            info={t(item.info)}
            key={key}
          />
        ))}
      </Carousel> */}
    </Section>
  );
};
